import { login } from '../../api/login';
import { getUser } from '../../api/getUser';
// TODO: добавить LOGOUT в actions и в reducer
export const AUTH_REQUEST_SUBMIT = 'AUTH_REQUEST_SUBMIT';
export const AUTH_REQUEST_COMPLETED = 'AUTH_REQUEST_COMPLETED'; // используется для управления перенаправлением на /login в ProtectedRoute
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILURE = 'AUTH_FAILURE';
export const AUTH_ERROR = 'AUTH_ERROR';

export function authUser(loginData) {
  return function (dispatch) {
    dispatch({ type: AUTH_REQUEST_SUBMIT })
    const { username, password, shouldRecordToken } = loginData;
    login({ username, password })
      .then((data) => {
        const { username, jwtoken } = data;
        dispatch({ type: AUTH_SUCCESS, username });
        if (shouldRecordToken) {
          localStorage.setItem('jwtoken', jwtoken);
        } else {
          sessionStorage.setItem('jwtoken', jwtoken);
        }
      })
      .catch((err) => {
        if (err.status === 401) return dispatch({ type: AUTH_FAILURE });
        dispatch({ type: AUTH_ERROR })
      });
  }
}

export function checkAuth() {
  return function (dispatch) {
    dispatch({ type: AUTH_REQUEST_SUBMIT })
    const token = localStorage.getItem('jwtoken');
    if (!token) {
      dispatch({ type: AUTH_REQUEST_COMPLETED });
      return;
    }
    getUser(token)
      .then((data) => {
        const { username } = data;
        dispatch({ type: AUTH_SUCCESS, username });
      })
      .catch(() => dispatch({ type: AUTH_REQUEST_COMPLETED }));
  }
}
