import settings from '../settings.json';

export function login(credentials) {
  const { AUTH_ENDPOINT } = settings;
  const { REACT_APP_API_URL } = process.env;
  const url = new URL(AUTH_ENDPOINT, REACT_APP_API_URL);
  const headers = new Headers();
  headers.append('Content-type', 'application/json');

  const options = {
    method: 'POST',
    headers,
    body: JSON.stringify(credentials),
  }

  return fetch(url, options)
    .then((res) => {
      if (res.status === 200) return res.json();
      return Promise.reject(res)
    })
    .then((token) => token)
    .catch((err) => Promise.reject(err));
}
