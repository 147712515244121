/* eslint-disable react-hooks/exhaustive-deps */
import './login-form.css';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import { authUser } from '../../services/actions/authActions';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

export const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { isAuth, isAuthFailure, isAuthError } = useSelector((store) => store.auth);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [shouldRecordToken, setShouldRecordToken] = useState(false);

  useEffect(() => {
    if (isAuth && state) navigate(state.from);
  }, [isAuth]);

  const onPasswordChange = (e) => {
    setPassword(e.currentTarget.value);
  }

  const onUsernameChange = (e) => {
    setUsername(e.currentTarget.value);
  }

  const onCheckboxChange = () => {
    setShouldRecordToken(!shouldRecordToken);
  }

  const handleFormSubmit = (e) => {
    e.preventDefault();
    dispatch(authUser({ username, password, shouldRecordToken }))
  }

  return (
    <div className='wrapper d-flex align-items-center justify-content-center w-100'>
      <Form onSubmit={handleFormSubmit}>
        <Form.Group className='mb-3' controlId='formBasicEmail'>
          <Form.Label>User name</Form.Label>
          <Form.Control
            type='username'
            placeholder='Enter user name'
            value={username}
            onChange={onUsernameChange}
          />
        </Form.Group>

        <Form.Group className='mb-3' controlId='formBasicPassword'>
          <Form.Label>Password</Form.Label>
          <Form.Control
            type='password'
            placeholder='Password'
            value={password}
            onChange={onPasswordChange}
          />
        </Form.Group>
        <Form.Group className='mb-3' controlId='formBasicCheckbox'>
          <Form.Check
            type='checkbox'
            label='Remember me'
            onChange={onCheckboxChange}
          />
        </Form.Group>

        <Button variant='primary' type='submit' className='w-100'>
          Submit
        </Button>
        {isAuthFailure &&
          <h5>Неверное имя пользователя или пароль</h5>
        }
        {isAuthError &&
          <h5>Что-то пошло не так. Повторите позже.</h5>
        }
      </Form>
    </div>
  )
}
