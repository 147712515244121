import { rootReducer } from '../reducers/rootReducer';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';

// FIX:в продакшене отображает какую-то херню. Экшены Яндекс-Метрика
export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);
