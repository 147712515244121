/* eslint-disable react-hooks/exhaustive-deps */
import { Routes, Route, Outlet } from 'react-router-dom';
import { FrontPage } from '../front-page/front-page';
import { LoginForm } from '../login-form/login-form';
import { Header } from '../header/header';
import { ProtectedRoute } from '../protected-route/protected-route';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { checkAuth } from '../../services/actions/authActions';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkAuth());
  }, [])

  return (
    <Routes>
      <Route path='/'
        element={
          <>
            <Header />
            <Outlet />
          </>
        }
      >
        <Route index element={<FrontPage />} />
        <Route path='login' element={<LoginForm />} />

        <Route path='user'
          element={
            <ProtectedRoute>
              <h3>path /user</h3>
              {/* здесь была AdminPanel потом добавим что будет нужно */}
            </ProtectedRoute>
          }
        />
      </Route>
    </Routes>
  );
}

export default App;
