import Container from 'react-bootstrap/Container';

export function FrontPage() {
  // console.log('process.env', process.env)
  return (
    <Container className='min-width'>
      <div className='pt-5  rounded'>
        <h1>Здесь пока ничего нет, но возможно когда-то появится... </h1>
      </div>
    </Container>
  )
}
