import {
  AUTH_REQUEST_SUBMIT,
  AUTH_REQUEST_COMPLETED,
  AUTH_SUCCESS,
  AUTH_FAILURE,
  AUTH_ERROR,
} from '../actions/authActions';
import { createReducer } from '@reduxjs/toolkit';

const initState = {
  isAuthPending: false,
  isAuthCompleted: false,
  isAuthFailure: false,
  isAuthError: false,
  isAuth: false,
  username: null,
}

export const authReducer = createReducer(initState, (builder) => {
  builder
    .addCase(AUTH_REQUEST_SUBMIT, (state) => {
      state.isAuthPending = true;
      state.isAuthFailure = false;
    })
    .addCase(AUTH_SUCCESS, (state, action) => {
      state.isAuthPending = false;
      state.isAuthCompleted = true;
      state.isAuthFailure = false;
      state.isAuthError = false;
      state.isAuth = true;
      state.username = action.username;
    })
    .addCase(AUTH_FAILURE, (state) => {
      state.isAuthPending = false;
      state.isAuthCompleted = true;
      state.isAuthError = false;
      state.isAuthFailure = true;
    })
    .addCase(AUTH_ERROR, (state) => {
      state.isAuthPending = false;
      state.isAuthCompleted = true;
      state.isAuthError = true;
      state.isAuthFailure = false;
    })
    .addCase(AUTH_REQUEST_COMPLETED, (state) => {
      state.isAuthPending = false;
      state.isAuthCompleted = true;
    })
});
