import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';

export function Header() {
  const { isAuth, username } = useSelector((store) => store.auth);

  return (
    <Container>
      <div className='d-flex flex-row border-bottom justify-content-between align-items-baseline pt-3 pb-3'>
        <Link to='/' className='text-decoration-none'>
          <h2 className='m-0'>Terra Codes</h2>
        </Link>

        {isAuth &&
          <p className='m-0'>{username}</p>
        }

        {!isAuth &&
          <Link to='/login' className='text-decoration-none'>
            <p className='m-0'>
              {isAuth ? username : 'Sign in'}
            </p>
          </Link>
        }
      </div>
    </Container>
  )
}
