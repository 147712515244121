import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

export function ProtectedRoute(props) {
  const { children } = props;
  const location = useLocation();
  const { isAuth, isAuthPending, isAuthCompleted } = useSelector((store) => store.auth);

  if (isAuthPending) return null;

  if (isAuth) return children;

  if (!isAuth && isAuthCompleted) return (
    <Navigate to='/login' state={{ from: location }} />
  )

}
