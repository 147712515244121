import settings from '../settings.json';

export function getUser(token) {
  const { ME_ENDPOINT } = settings;
  const { REACT_APP_API_URL } = process.env;
  const url = new URL(ME_ENDPOINT, REACT_APP_API_URL);

  const headers = new Headers();
  headers.append('Authorization', `Bearer ${token}`);
  headers.append('Content-type', 'application/json');

  const options = {
    method: 'GET',
    // credentials: 'include',
    headers,
  }

  return fetch(url, options)
    .then((res) => {
      if (res.status === 200) return res.json();
      return Promise.reject(res);
    })
    .then((data) => data)
    .catch((err) => Promise.reject(err));
}
